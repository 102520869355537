/* Project specific Sass goes here. */
#branding h1, #branding h1 a:link, #branding h1 a:visited {
    color: #fff!important;
}
#site-name img {margin-right:10px;}
$blu-cilia: #14489f;
$fucsia-cilia: #e90e8b;
$celeste: #62a8ea;
$blu-euro: #0061af;
$sfondo-euro: #e6e6ed;
$font-open-sans: 'Open Sans', sans-serif !default;
$font-roboto: 'Roboto', sans-serif !default;

.btn {
  font-weight: 300
}

.btn-link {
  cursor: pointer;
  padding: 0;
}

.btn-primary.disabled, .btn-primary:disabled {
  background-color: #ccc;
  border-color: #999;
}

.btn-remove {
  color: #d9534f;
  &:hover {
    color: #c9302c;
  }
}

.white {
  color: #fff;
}

.ss-pink {
  color: $fucsia-cilia !important;
}

.fa-check-square-o {
  color: green;
}

.fa-times {
  color: #ff0000;
}

button[type="submit"] .fa-times {
  color: #fff;
}

.pistola {
  background: url('../images/barcode_grey.png') no-repeat;
  background-size: 8px 14px;
  padding-left: 20px;
}

.overflow {
  overflow: hidden
}

html {
  height: 100%;
  box-sizing: border-box;
}

body {
  font-family: $font-roboto;
  font-size: 14px;
  line-height: 1.5;
  color: #76838f;
  font-weight: 300;
  background: #f1f4f5;
  position: relative;
  min-height: 100%;

}

#icon-cilia {
  fill: #fff;
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
  font-weight: 400;
  line-height: 1.2;
  color: #37474f;
  text-shadow: rgba(0, 0, 0, .15) 0 0 1px;
}

b {
  font-weight: 400;
}

.black500 {
  color: #000;
  font-weight: 500;
}

.bg-blue {
  background-color: #62a8ea;
  color: #fff;
}

strong {
  font-weight: 500
}

.thin {
  font-weight: 300
}

.width-50 {
  width: 50px;
}

.width-250 {
  width: 250px;
}

.width-350 {
  width: 350px;
}

.width-450 {
  width: 450px;
}

.size-20 {
  font-size: 20px !important;
}

/*
 * Badge
 */
.badge {
  font-weight: 500;
  padding: .25em .6em;
  &.badge-normal-size {
    font-size: 100%;
  }
  &.badge-default-outline {
    background: #fff;
    color: #636c72;
    border: 1px solid #636c72;
  }
  &.badge-primary-outline {
    background-color: #fff;
    color: #0275d8;
    border: 1px solid #0275d8;
  }
  &.badge-success-outline {
    background-color: #fff;
    color: #5cb85c;
    border: 1px solid #5cb85c;
  }
  &.badge-info-outline {
    background-color: #fff;
    color: #5bc0de;
    border: 1px solid #5bc0de;
  }
  &.badge-warning-outline {
    background-color: #fff;
    color: #f0ad4e;
    border: 1px solid #f0ad4e;
  }
  &.badge-danger-outline {
    background-color: #fff;
    color: #d9534f;
    border: 1px solid #d9534f;
  }
}

/*
 * Navigation
 */
#mainNav {
  height: 60px;
  border: none;
  -webkit-box-shadow: 0 2px 4px rgba(0, 0, 0, .08);
  box-shadow: 0 2px 4px rgba(0, 0, 0, .08);
  background: #fff;
  padding: 0px;
  .navbar-brand {
    padding: 0px;
    .navbar-brand-logo {
      height: 40px;
      margin-left: 10px;
      margin-top: 10px;
    }
  }
  .navbar-nav {
    .nav-item {
      height: 60px;
    }
    .nav-link {
      line-height: 20px;
      padding: 20px;
      i {
        font-size: 18px;
      }
      &.item-upper {
        position: relative;
      }
      .badge {
        position: absolute;
        top: 12px;
        right: 12px;
      }
    }
    .nav-item:hover {
      color: #526069;
      background-color: rgba(243, 247, 249, .6);
    }
    .nav-item + .nav-item {
      margin-left: 0px;
    }
  }
  .dropdown-menu {
    right: 2px !important;
    left: inherit;
    padding: 3px;
    min-width: 13rem;
    .benv {
      font-size: 12px;
      color: #76838f;
      padding: 8px 15px;
      margin: 0;
      b {
        color: $fucsia-cilia;
      }
    }
  }
  .dropdown-menu > a {
    font-size: 14px;
    color: #76838f;
    font-weight: 100;
    padding: 8px 15px;
    border-radius: 3px;
    -webkit-transition: background-color .25s;
    -o-transition: background-color .25s;
    transition: background-color .25s;
    &:focus, &:hover {
      color: #76838f;
      text-decoration: none;
      background-color: #f3f7f9;
    }
    i {
      margin-right: 5px;
    }
  }
}

#menuBar {
  height: 100%;
  width: 60px;
  position: fixed;
  z-index: 1000;
  top: 60px;
  height: -webkit-calc(100% - 60px);
  height: calc(100% - 60px);
  background: #354052;

  /*background: #263238;
  color: rgba(163, 175, 183, .9);*/
  color: #6f7e95;
  font-family: Roboto, sans-serif;
  -webkit-box-shadow: 0 2px 4px rgba(0, 0, 0, .08);
  box-shadow: 0 2px 4px rgba(0, 0, 0, .08);
  -webkit-transition: width .25s, opacity .25s, -webkit-transform .25s;
  -o-transition: width .25s, opacity .25s, -o-transform .25s;
  transition: width .25s, opacity .25s, transform .25s;

  li:hover {
    background-color: #2b3647;
    .categories-panel {
      left: 60px;
      /*opacity: 1;*/
    }
  }

  .nav-item {
    .parent {
      color: rgba(163, 175, 183, .9);
      display: block;
      line-height: 20px;
      padding: 20px;
      &:hover {
        color: rgba(255, 255, 255, 1);
      }
      i {
        font-size: 18px;
      }
    }
    .categories-panel {
      padding: 20px;
      background-color: #2b3647;
      color: rgba(163, 175, 183, .9);
      -moz-box-shadow: 2px 0 0 0 rgba(0, 0, 0, 0.49);
      -webkit-box-shadow: 2px 0 0 0 rgba(0, 0, 0, 0.49);
      box-shadow: 2px 0 0 0 rgba(0, 0, 0, 0.49);
      height: 100%;
      width: 220px;
      position: fixed;

      top: 60px;
      /*left:60px;
      opacity: 0;*/
      left: -400px;
      z-index: -1;
      transition: all 400ms ease-in-out;
      /*transition: all 400ms cubic-bezier(1.000, 0.000, 0.000, 1.000);
      -webkit-transition: all 400ms cubic-bezier(1.000, 0.000, 0.000, 1.000);
      -moz-transition: all 400ms cubic-bezier(1.000, 0.000, 0.000, 1.000);*/
      h2 {
        font-size: 18px;
        color: #fff;
        font-weight: 500;
        margin-bottom: 20px;
      }
      .scroll-pane .nav-item {
        padding: 5px 0px;
        text-transform: uppercase;
        font-weight: 500;
        font-size: 12px;
        &.white {
          border-bottom: 1px solid;
        }
      }

      a {
        color: rgba(163, 175, 183, .9);
        font-size: 14px;
        font-weight: 300;
        text-decoration: none;
        text-transform: none !important;
        border-bottom: none;
        i {
          margin-right: 5px;
        }
        &:hover {
          color: rgba(255, 255, 255, 1);
        }
      }
    }
  }

}

#main-content {
  min-height: 700px;
  margin: 60px 0px 0px 60px;
  padding-top: 20px;
}

.page {
  margin: 60px 0px 0px 60px;
  background: #f1f4f5;
  min-height: -webkit-calc(100% - 44px);
  min-height: 600px;
  min-height: calc(100% - 44px);
  position: relative;
}

.page-content {
  padding: 30px 30px;
}

footer {
  position: absolute;
  clear: both;
  bottom: 0px;
  font-size: .8em;
  width: 100%;
  /*margin-left: 60px;
  background-color: #f7f7f9;*/
  p {
    margin-bottom: 0px;
  }
}

.site-footer {
  padding: 15px 30px 15px 70px;
  background-color: rgba(0, 0, 0, .02);
  border-top: 1px solid #e4eaec;
  height: 44px;
}

/*
 * Messagges
 */
#message {
  padding: 0px;
}

/*
 * Page header
 ****************************/
.page-header + .page-content {
  padding-top: 0;
}

.page-header {
  padding: 30px 30px;
  margin-top: 0;
  margin-bottom: 0;
  background: none;
  position: relative;
  border-bottom: none;
  .page-title {
    font-size: 26px;
    margin-bottom: 0px;
    margin-top: 0;
  }
  .breadcrumb {
    padding: 0;
    margin: 0;
  }
}

.page-header-bordered {
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: #fff;
  margin-bottom: 30px;
  border-bottom: 1px solid transparent;
}

.page-header-actions {
  position: absolute;
  right: 30px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  margin: auto;
  & > * {
    margin-bottom: 0;
  }
}

.total {
  text-align: center;
  border-radius: 3px;
  background-color: #62a8ea;
  color: #fff;
  padding: 8px 10px 0px;
  & > .total-number {
    font-size: 25px;
    line-height: 35px;
  }
  .total-label {
    text-transform: uppercase;
    font-size: 10px;
    font-weight: 300;
    line-height: 1em;
  }
}

.counter {
  text-align: center;
  margin-left: 10px;
  & > .counter-number {
    color: #37474f;
    font-size: 20px;
  }
  .counter-label {
    display: block;
  }
}

.font-weight-medium {
  font-weight: 400 !important;
}

.row.no-space {
  margin-right: 0;
  margin-left: 0;
}

.row.no-space > [class*="col-"] {
  padding-right: 0;
  padding-left: 0;
}

/*
 * Tag
 ******************/
.tag {
  font-weight: 400;
}

/* Breadcrumbs */
.breadcrumb {
  background-color: transparent;
}

.breadcrumb > li {
  display: inline-block;
  a {
    color: #62a8ea;
  }
  a:hover {
    color: #89bceb;
  }
  a:active {
    color: #4e97d9;
  }
}

.breadcrumb li + li::before {
  padding: 0 5px;
}

.breadcrumb > li + li::before {
  padding: 0 5px;
  color: #62a8ea;
  content: "/\00a0";
}

/*
 * Widget
 ***********************/
.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: normal;
  white-space: nowrap;
}

.widget {
  border-radius: 3px;
  background-color: #fff;
  margin-bottom: 30px;
  padding-bottom: 25px;
  position: relative;
  .widget-header {
    padding: 25px 30px 11px;
    border-radius: 3px 3px 0 0;
    /*color: #37474f !important;*/
    color: #e90e8b !important;
    font-size: 14px;
    text-transform: uppercase;
    margin-bottom: 0px;
    font-weight: 400;
  }
  .widget-content {
    padding: 0 20px;
    &::after {
      content: "";
      display: block;
      clear: both;
    }
  }
  .widget-title {
    margin-top: 0;
    color: #37474f;
    text-transform: capitalize !important;
    font-size: 24px;
  }
  .widget-body {
    position: relative;
    padding: 30px 25px;
  }
  .widget-header.no-padding + .widget-body {
    padding-top: 10px !important;
  }
  .table {
    color: #76838f !important;
  }
}

.widget-shadow {
  -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, .05);
  box-shadow: 0 1px 1px rgba(0, 0, 0, .05);
}

/*
 * Panel
 ***********************/
.widget {
  border-radius: 3px;
  background-color: #fff;
  margin-bottom: 30px;
  position: relative;
}

.widget-shadow {
  -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, .05);
  box-shadow: 0 1px 1px rgba(0, 0, 0, .05);
}

.panel {
  position: relative;
  margin-bottom: 30px;
  border-width: 0;
  background-color: #fff;
  border: 1px solid transparent;
  border-radius: 4px;
  -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, .2);
  box-shadow: 0 1px 1px rgba(0, 0, 0, .2);
  .panel-heading {
    position: relative;
    padding: 0;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
  }
  .panel-center {
    padding: 30px;
    overflow: hidden;
    margin: 0 auto;
    width: 300px;
    .mese-stato {
      font-size: 20px;
      margin: 0px 10px;
    }
  }
  .panel-actions {
    position: absolute;
    width: auto;
    text-align: right;
    top: 50%;
    right: 30px;
    z-index: 1;
    margin: auto;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    -o-transform: translate(0, -50%);
    transform: translate(0, -50%);
    .panel-action {
      display: inline-block;
      padding: 8px 10px;
      color: #a3afb7;
      text-decoration: none;
      cursor: pointer;
      background-color: transparent;
      font-size: 18px;
    }
  }
  .panel-body {
    position: relative;
    padding: 30px 30px;
  }
  .panel-title {
    display: block;
    padding: 20px 30px;
    font-size: 18px;
    color: #37474f;
    margin-top: 0;
    margin-bottom: 0;
  }
  .panel-desc {
    display: block;
    padding: 5px 0 0;
    margin: 0;
    font-size: 14px;
    color: #76838f;
  }
  .panel-footer {
    padding: 0 30px 15px;
    background-color: transparent;
    border-top: 1px solid #e4eaec;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
  }
  & > .alert-dismissible {
    padding-right: 50px;
  }
  & > .alert {
    padding-right: 30px;
    padding-left: 30px;
    margin: 0;
  }
  .result_search {
    display: inline;
    float: left;
    font-size: 1.5em;
    margin-top: 5px;

    em {
      font-weight: 400;
      color: #000;
    }
  }

}

.panel-heading + .panel-body {
  padding-top: 0px;
}

.panel-heading + .panel-body.padding {
  padding-top: 30px;
}

.panel-heading + .alert {
  border-radius: 0;
}

.panel-bordered {
  & > .panel-heading {
    border-bottom: 1px solid #e4eaec;
  }
  & > .panel-heading > .panel-title {
    padding-bottom: 20px;
  }
  & > .panel-footer {
    padding-top: 15px;
    border-top: 1px solid #e4eaec;
  }
  .panel-body {
    padding-top: 30px;
  }
}

.panel-danger, .panel-dark, .panel-info, .panel-primary, .panel-success, .panel-warning {
  border: none;
}

.panel-primary > .panel-heading {
  color: #fff;
  background-color: #62a8ea;
  border-color: #62a8ea;
}

.panel-success > .panel-heading {
  color: #fff;
  background-color: #46be8a;
  border-color: #3dae6a;
}

.panel-danger > .panel-heading {
  color: #fff;
  background-color: #d9534f;
  border-color: #d9534f;
}

.panel-info > .panel-heading {
  color: #fff;
  background-color: #5bc0de;
  border-color: #5bc0de;
}

.panel-warning > .panel-heading {
  color: #fff;
  background-color: #f18246;
  border-color: #f18246;
}

.panel-danger .panel-title, .panel-dark .panel-title, .panel-info .panel-title, .panel-primary .panel-title, .panel-success .panel-title, .panel-warning .panel-title {
  color: #fff;
}

input::-webkit-input-placeholder, input::-moz-placeholder, input:-ms-input-placeholder, input:-moz-placeholder {
  color: #CCCCCC !important;
}

#toast-container > .toast {
  background-image: none !important;
}

#toast-container > .toast:before {
  position: fixed;
  font-family: FontAwesome;
  font-size: 24px;
  line-height: 18px;
  float: left;
  color: #FFF;
  padding-right: 0.5em;
  margin: auto 0.5em auto -1.5em;
}

#toast-container > .toast-warning:before {
  content: "\f06a";
}

#toast-container > .toast-error:before {
  content: "\f256";
}

#toast-container > .toast-info:before {
  content: "\f05a";
}

#toast-container > .toast-success:before {
  content: "\f00c";
}

/*
 * Page: Fdp mese
 */
#fdp-panel {
  .panel-title {
    font-size: 22px;
  }
  .panel-body {
    padding: 0px 30px 30px;
  }
}

.month-nav {
  overflow: hidden;
  & > * {
    float: left;
    margin-left: .75em;
  }
  button[name='next'] {
    margin-left: 0px;
  }
  .btn:focus {
    box-shadow: none !important;
    -webkit-box-shadow: none !important;
  }
}

.table-info {
  font-weight: 600;
}
.cell-grey {
  background: #f8f8f8!important;
}

#report_fdp_table {
  tbody tr:hover {
    background-color: rgba(0, 0, 0, .035);
  }
  .novpa, .vpa {
    border: 1px solid #eceeef;
    text-align: center;
    vertical-align: middle;
  }
  .tot-vpa {
    color: #292b2c;
    font-weight: 400;
  }
  .cod-vpa {
    border: none;
  }
}

#riepilogo_table td, #report_fdp_table td {
  color: #292b2c;
  .text-center {
    font-weight: 400;
  }
}

#riepilogo_table {
  .data {
    min-width: 95px;
    width: 95px;
  }
  .tempi {
    min-width: 50px;
    width: 50px;
  }
}

.tot-servizi {
  float: left;
  width: 150px;
  border-right: dotted 3px #ccc;
  margin-right: 20px;

}

.lista-codici-title, .tot-servizi-title {
  font-weight: 300;
  font-size: 16px;
}

.vpa-tot {
  width: 60px;
  height: 65px;
  border-radius: 4px;
  color: #fff;
  display: inline-block;
  margin-right: 5px;
  margin-bottom: 5px;
  padding: 5px;
  text-align: center;
  font-size: 20px;
  font-weight: 300;
  position: relative;
  &.vbig {
    width: 120px;
    font-size: 16px;
    padding-top: 10px;
  }
  .time {
    font-weight: 500;
    display: block;
    font-size: 14px;
    margin-top: 3px;
    border-radius: 0px 0px 2px 2px;
    color: #000;
    background: #fff;
  }
}

#cambia-autista {
  float: left;
  margin-right: 15px;
  padding-right: 15px;
  border-right: 1px solid #ccc;
}

/*
 * Pagina creazione barcode
 */
#crea-barcode-form {
  label {
    margin-top: 40px;
    text-align: right;
    text-align: right;
    font-weight: 500;
    text-transform: uppercase;
    display: block;
  }
  .help {
    font-style: italic;
    margin-bottom: .5rem;
  }
}

/*
 * Page: Fogli servizi mese
 */
#fservizio-panel {
  .panel-title {
    font-size: 22px;
  }
  .panel-body {
    padding: 0px 30px 30px;
  }
}

form .form-control-feedback {
  margin-left: 15px;
  color: red;
  font-weight: 500;
}

/*
 * Page: Operativo home
 */
#grafico_fogli_presenza {
  margin: 30px auto;
  width: 350px;
  height: auto;
}

/*
 * Page: Gestionale home
 */
#grafico_clienti {
  margin: 30px auto;
  width: 100%;
  max-width: 500px;
  height: auto;
}

/*
 * Tooltips
 */
.tooltip-inner {
  font-size: 12px;
}

/*
 * Pagination
 **********************/
#pagination {
  margin: 15px;
  /*float: right;*/
  width: auto;
  text-align: right;
  .current {
    float: left;
    font-size: 14px;
    margin-right: 15px;
    margin-top: 10px;
  }
  ul {
    margin: 0px;
  }
}

#totali {
  .numero {
    z-index: 2;
    color: #fff;
    font-weight: 600;
    background-color: #a25808;
    border-color: #f18246;
    border-radius: .25rem;
    padding: .5rem .75rem;
    margin-left: 10px;
  }
}

/*
 * Table
 *********************/
#toolbar {
  margin-top: 10px;
}

.fixed-table-container {
  border: none !important;
}

.fixed-table-toolbar .columns label {
  font-size: 13px;
  padding: 3px 10px !important;
  font-weight: 300;
}

.fixed-table-body {
  tr > td {
    font-size: 12px;
  }
  margin: 0px 0px 20px;
}

.bootstrap-table .table {
  border: none !important;
  & > thead > tr > th {
    font-size: 11px;
    text-transform: uppercase;
    border-bottom: 2px solid #eceeef !important;
  }
  & > tr > td, table .table:not(.table-condensed) > tbody > tr > td {
    font-size: 12px;
  }
}

.fixed-table-pagination .pagination-info {
  margin-right: 20px !important;
}

.fixed-table-pagination .dropup .dropdown-menu {
  margin-bottom: 1px !important;
  min-width: 6rem !important;
}

.fixed-table-toolbar, .fixed-table-pagination {
  .dropdown-menu > li a {
    font-size: 14px;
    color: #76838f;
    font-weight: 100;
    display: block;
    padding: 0px 15px;
    border-radius: 3px;
    -webkit-transition: background-color .25s;
    -o-transition: background-color .25s;
    transition: background-color .25s;
    &:focus, &:hover {
      color: #76838f;
      text-decoration: none;
      background-color: #f3f7f9;
    }
    i {
      margin-right: 5px;
    }
  }
}

.fixed-table-pagination {
  .page-number.active {
    background: #0275d8;
    border-radius: 3px;
    a {
      color: #fff;
      text-decoration: none;
      font-weight: 400;
    }
  }
  div.pagination {
    margin-top: 20px !important;
  }
  .pagination-detail {
    margin-top: 10px;
  }
}

.table-success, .table-success > td, .table-success > th {
  background-color: #dff0d8;
  color: green;
  font-weight: 600;
}

.table-danger, .table-danger > td, .table-danger > th {
  background-color: #f2dede;
  color: darkred;
  font-weight: 600;
}

.table th {
  font-weight: 500;
  //color: #526069;
  color: #292b2c;
}

.thead-default th {
  background-color: transparent !important;
}

.table-sm td, .table-sm th {
  font-size: 12px;
}

#fogli_presenze_table {
  .progress {
    margin-top: 4px;
  }
}

table.month {
  width: 100%;
  th {
    font-weight: 400;
    text-transform: uppercase;
    text-align: center;
    &.month {
      text-transform: none;
      font-size: 20px;
      font-weight: 400;
      line-height: 1.2;
      color: #37474f;
      text-shadow: rgba(0, 0, 0, .15) 0 0 1px;
      border-top: 0;
      padding-bottom: 20px;
    }
  }
  tr {
    td {

      min-width: 150px;
      width: -webkit-calc(100% / 7);
      width: -moz-calc(100% / 7);
      width: calc(100% / 7);
      position: relative;
      height: 100px;
      min-height: 100px;
      padding: 30px 5px 5px;
      .add-vpa {
        position: absolute;
        top: 1px;
        left: 5px;
        z-index: 100;
        &:focus {
          outline: none !important;
        }
      }
      .cr-day-number {
        font-weight: 500;
        color: #37474f;
        font-size: 20px;
        position: absolute;
        right: 5px;
        top: 1px;
      }
      &.tue, &.wed, &.thu, &.fri, &.sat, &.sun {
        border-left: 1px solid #eceeef;
      }
      &.riposo, & + .riposo {
        background-color: #fcf8e3;
        .cr-day-number {
          color: #d9534f;
        }
      }
      &.zeroore, & + .zeroore {
        .cr-day-number {
          color: #0275d8;
        }
      }
      .vpa-item {
        background: #f3f7f9;
        position: relative;
        padding: 0px 15px 0px 5px;
        height: auto;
        line-height: 22px;
        color: #37474f;
        border-radius: 3px;
        margin-bottom: 2px;
        font-size: 11px;
        &.OR {
          background: #5cb85c;
          color: #fff !important;
        }
        &.ST, &.SF, &.FM, &.MN {
          background: #5bc0de;
          color: #fff;
        }
        &.FE, &.CM, &.PE, &.PL, &.PR, &.SR, &.VM {
          background: #d9534f;
          color: #fff;
        }
        &.ML, &.IN, &.P5, &.P4 {
          background: #f0ad4e;
          color: #fff;
        }
        &.DS {
          background: #0275d8;
          color: #FFF;
        }
        .vpa-item-time {
          font-weight: 500;
          background: #fff;
          color: #000;
          padding: 1px 3px;
          border-radius: 2px;
          margin-right: 3px;
        }
        .btn-remove {
          position: absolute;
          line-height: 1em;
          right: 0px;
          top: 1px;
          padding:0px 2px 0px 0px;
        }
      }
    }
  }
}

/*
 * Form
 **********************/
#foglio_servizio_form {
  .form-group {
    margin-bottom: 2rem;
  }
  .comune-title {
    color: $celeste;
    font-weight: 600;
    font-size: 16px;
    margin-bottom: 20px;
    text-transform: uppercase;
  }
  .turno-line {
    margin-bottom: 20px;
    overflow: hidden;
  }
  .turno {
    .nome-turno {
      margin-bottom: 0;
      font-size: 16px;
    }
    .orario {
      color: #76838f;
      font-size: 12px;
      font-weight: 400;
      margin-bottom: 0;
    }
  }
}

#formFogliMarcia {
  .form-control-sm, .input-group-sm > .form-control, .input-group-sm > .input-group-addon, .input-group-sm > .input-group-btn > .btn {
    font-size: .775rem !important;
  }
}

#vpaModalForm, #codSuppModalForm {
  label {
    font-weight: 400;
  }
  #p_m_giorno {
    margin-bottom: 0px;
  }
  .badge {
    font-size: 95%;
  }
  .help-block {
    margin: 5px 30px;
    color: #d9534f;
  }
}

/*
 * Calendar
 ************************/
.fc td.fc-widget-header {
  padding-top: 20px;
  padding-bottom: 20px;
  border-width: 0;
  font-size: 14px;
  text-transform: uppercase
}

.fc td.fc-widget-content {
  border-width: 1px 0 0
}

.fc td.fc-day.fc-widget-content + .fc-widget-content {
  border-left-width: 1px
}

.fc .fc-row {
  border-width: 0
}

.fc table > thead > tr > th {
  border-width: 0;
  font-weight: 100
}

.fc .fc-toolbar h2 {
  display: inline-block;
  font-size: 20px;
  vertical-align: sub
}

.fc .fc-axis.fc-time {
  text-transform: uppercase
}

.fc .fc-toolbar {
  position: relative
}

.fc-button.fc-next-button, .fc-button.fc-prev-button {
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  background-image: none;
  background-color: transparent;
  border: 0 solid transparent
}

.fc-button.fc-agendaDay-button, .fc-button.fc-agendaWeek-button, .fc-button.fc-month-button {
  height: auto;
  padding: 8px 14px;
  font-size: 14px;
  -webkit-box-shadow: none;
  box-shadow: none;
  background-image: none;
  text-transform: capitalize;
  background-color: #f6f8f8;
  border-color: #e4eaec;
  outline: 0
}

.fc-button.fc-state-active, .fc-button.fc-state-hover {
  background-color: #fff
}

.fc-unthemed .fc-today {
  background-color: #f4f7f8
}

.fc-toolbar .fc-right {
  position: absolute;
  right: 0;
  top: 0
}

.fc-agendaDay-view .fc-event, .fc-agendaWeek-view .fc-event {
  border-radius: 3px
}

.fc-row.fc-widget-header {
  border-color: transparent
}

.calendar-container {
  overflow: hidden;
  padding: 30px
}

.panel-heading {
  border-width: 0;
  padding-top: 10px
}

.panel-body {
  padding-top: 10px
}

.page-main {
  background-color: #fff
}

.calendar-list .list-group-item {
  cursor: pointer
}

.fc-event {
  border-radius: 10px;
  background-color: #62a8ea;
  border-color: #62a8ea
}

.fc-day-grid-event .fc-content {
  line-height: 1em;
  padding: 2px 10px
}

.fc-highlight {
  background: #f3f7f9
}

.bootstrap-touchspin .input-group-btn-vertical i {
  left: 3px
}

@media (max-width: 991px) {
  .fc-toolbar {
    margin-bottom: 60px
  }
  .fc-toolbar .fc-center {
    white-space: nowrap
  }
  .fc-toolbar .fc-right {
    top: 50px
  }
  .fc-button.fc-prev-button {
    padding-left: 0
  }
  .fc-button.fc-next-button {
    padding-right: 0
  }
}

/* Notify */
.alertify-logs {
  z-index: 99999 !important;
}

.alert-error {
  background-color: #f2dede;
  border-color: #ebcccc;
  color: #a94442;
}

/* Absolute Center Spinner */
#wait {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: visible;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  color: #000;
}

/* Transparent Overlay */
#wait:before {
  content: '';
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.7);
}
