.api-style {
  #content {
    margin-top: 90px;
  }
  .api-brand {
    padding: 5px 15px;
  }
  .nav li {
    color: #76838f;
  }
  .navbar {
    border-top: 5px solid #354052;
    box-shadow: 0 2px 4px rgba(0, 0, 0, .08);
    background: #fff;
    color: #76838f;
    .nav li a, .brand:hover {
      color: #76838f;
    }
  }
  .page-header {
    padding: 0px 230px 0px 0px;
    position: inherit;
  }
  .icon-exit {
    padding: 0px;
    &:hover img {
      fill:#000;
    }
  }
}