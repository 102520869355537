.xdsoft_datetimepicker {
  -webkit-box-shadow: 0 5px 15px -5px rgba(0, 0, 0, 0.506);
          box-shadow: 0 5px 15px -5px rgba(0, 0, 0, 0.506);
  background: #fff;
  border-bottom: 1px solid #bbb;
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
  border-top: 1px solid #ccc;
  color: #333;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  padding: 8px;
  padding-left: 0;
  padding-top: 2px;
  position: absolute;
  z-index: 9999;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  display: none; }

.xdsoft_datetimepicker iframe {
  position: absolute;
  left: 0;
  top: 0;
  width: 75px;
  height: 210px;
  background: transparent;
  border: none; }

/*For IE8 or lower*/
.xdsoft_datetimepicker button {
  border: none !important; }

.xdsoft_noselect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none; }

.xdsoft_noselect::-moz-selection {
  background: transparent; }

.xdsoft_noselect::selection {
  background: transparent; }

.xdsoft_noselect::-moz-selection {
  background: transparent; }

.xdsoft_datetimepicker.xdsoft_inline {
  display: inline-block;
  position: static;
  -webkit-box-shadow: none;
          box-shadow: none; }

.xdsoft_datetimepicker * {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding: 0;
  margin: 0; }

.xdsoft_datetimepicker .xdsoft_datepicker, .xdsoft_datetimepicker .xdsoft_timepicker {
  display: none; }

.xdsoft_datetimepicker .xdsoft_datepicker.active, .xdsoft_datetimepicker .xdsoft_timepicker.active {
  display: block; }

.xdsoft_datetimepicker .xdsoft_datepicker {
  width: 224px;
  float: left;
  margin-left: 8px; }

.xdsoft_datetimepicker.xdsoft_showweeks .xdsoft_datepicker {
  width: 256px; }

.xdsoft_datetimepicker .xdsoft_timepicker {
  width: 58px;
  float: left;
  text-align: center;
  margin-left: 8px;
  margin-top: 0; }

.xdsoft_datetimepicker .xdsoft_datepicker.active + .xdsoft_timepicker {
  margin-top: 8px;
  margin-bottom: 3px; }

.xdsoft_datetimepicker .xdsoft_mounthpicker {
  position: relative;
  text-align: center; }

.xdsoft_datetimepicker .xdsoft_label i,
.xdsoft_datetimepicker .xdsoft_prev,
.xdsoft_datetimepicker .xdsoft_next,
.xdsoft_datetimepicker .xdsoft_today_button {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGQAAAAeCAYAAADaW7vzAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6Q0NBRjI1NjM0M0UwMTFFNDk4NkFGMzJFQkQzQjEwRUIiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6Q0NBRjI1NjQ0M0UwMTFFNDk4NkFGMzJFQkQzQjEwRUIiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDpDQ0FGMjU2MTQzRTAxMUU0OTg2QUYzMkVCRDNCMTBFQiIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDpDQ0FGMjU2MjQzRTAxMUU0OTg2QUYzMkVCRDNCMTBFQiIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PoNEP54AAAIOSURBVHja7Jq9TsMwEMcxrZD4WpBYeKUCe+kTMCACHZh4BFfHO/AAIHZGFhYkBBsSEqxsLCAgXKhbXYOTxh9pfJVP+qutnZ5s/5Lz2Y5I03QhWji2GIcgAokWgfCxNvcOCCGKqiSqhUp0laHOne05vdEyGMfkdxJDVjgwDlEQgYQBgx+ULJaWSXXS6r/ER5FBVR8VfGftTKcITNs+a1XpcFoExREIDF14AVIFxgQUS+h520cdud6wNkC0UBw6BCO/HoCYwBhD8QCkQ/x1mwDyD4plh4D6DDV0TAGyo4HcawLIBBSLDkHeH0Mg2yVP3l4TQMZQDDsEOl/MgHQqhMNuE0D+oBh0CIr8MAKyazBH9WyBuKxDWgbXfjNf32TZ1KWm/Ap1oSk/R53UtQ5xTh3LUlMmT8gt6g51Q9p+SobxgJQ/qmsfZhWywGFSl0yBjCLJCMgXail3b7+rumdVJ2YRss4cN+r6qAHDkPWjPjdJCF4n9RmAD/V9A/Wp4NQassDjwlB6XBiCxcJQWmZZb8THFilfy/lfrTvLghq2TqTHrRMTKNJ0sIhdo15RT+RpyWwFdY96UZ/LdQKBGjcXpcc1AlSFEfLmouD+1knuxBDUVrvOBmoOC/rEcN7OQxKVeJTCiAdUzUJhA2Oez9QTkp72OTVcxDcXY8iKNkxGAJXmJCOQwOa6dhyXsOa6XwEGAKdeb5ET3rQdAAAAAElFTkSuQmCC); }

.xdsoft_datetimepicker .xdsoft_label i {
  opacity: 0.5;
  background-position: -92px -19px;
  display: inline-block;
  width: 9px;
  height: 20px;
  vertical-align: middle; }

.xdsoft_datetimepicker .xdsoft_prev {
  float: left;
  background-position: -20px 0; }

.xdsoft_datetimepicker .xdsoft_today_button {
  float: left;
  background-position: -70px 0;
  margin-left: 5px; }

.xdsoft_datetimepicker .xdsoft_next {
  float: right;
  background-position: 0 0; }

.xdsoft_datetimepicker .xdsoft_next,
.xdsoft_datetimepicker .xdsoft_prev,
.xdsoft_datetimepicker .xdsoft_today_button {
  background-color: transparent;
  background-repeat: no-repeat;
  border: 0 none;
  cursor: pointer;
  display: block;
  height: 30px;
  opacity: 0.5;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
  outline: medium none;
  overflow: hidden;
  padding: 0;
  position: relative;
  text-indent: 100%;
  white-space: nowrap;
  width: 20px;
  min-width: 0; }

.xdsoft_datetimepicker .xdsoft_timepicker .xdsoft_prev,
.xdsoft_datetimepicker .xdsoft_timepicker .xdsoft_next {
  float: none;
  background-position: -40px -15px;
  height: 15px;
  width: 30px;
  display: block;
  margin-left: 14px;
  margin-top: 7px; }

.xdsoft_datetimepicker .xdsoft_timepicker .xdsoft_prev {
  background-position: -40px 0;
  margin-bottom: 7px;
  margin-top: 0; }

.xdsoft_datetimepicker .xdsoft_timepicker .xdsoft_time_box {
  height: 151px;
  overflow: hidden;
  border-bottom: 1px solid #ddd; }

.xdsoft_datetimepicker .xdsoft_timepicker .xdsoft_time_box > div > div {
  background: #f5f5f5;
  border-top: 1px solid #ddd;
  color: #666;
  font-size: 12px;
  text-align: center;
  border-collapse: collapse;
  cursor: pointer;
  border-bottom-width: 0;
  height: 25px;
  line-height: 25px; }

.xdsoft_datetimepicker .xdsoft_timepicker .xdsoft_time_box > div > div:first-child {
  border-top-width: 0; }

.xdsoft_datetimepicker .xdsoft_today_button:hover,
.xdsoft_datetimepicker .xdsoft_next:hover,
.xdsoft_datetimepicker .xdsoft_prev:hover {
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)"; }

.xdsoft_datetimepicker .xdsoft_label {
  display: inline;
  position: relative;
  z-index: 9999;
  margin: 0;
  padding: 5px 3px;
  font-size: 14px;
  line-height: 20px;
  font-weight: bold;
  background-color: #fff;
  float: left;
  width: 182px;
  text-align: center;
  cursor: pointer; }

.xdsoft_datetimepicker .xdsoft_label:hover > span {
  text-decoration: underline; }

.xdsoft_datetimepicker .xdsoft_label:hover i {
  opacity: 1.0; }

.xdsoft_datetimepicker .xdsoft_label > .xdsoft_select {
  border: 1px solid #ccc;
  position: absolute;
  right: 0;
  top: 30px;
  z-index: 101;
  display: none;
  background: #fff;
  max-height: 160px;
  overflow-y: hidden; }

.xdsoft_datetimepicker .xdsoft_label > .xdsoft_select.xdsoft_monthselect {
  right: -7px; }

.xdsoft_datetimepicker .xdsoft_label > .xdsoft_select.xdsoft_yearselect {
  right: 2px; }

.xdsoft_datetimepicker .xdsoft_label > .xdsoft_select > div > .xdsoft_option:hover {
  color: #fff;
  background: #ff8000; }

.xdsoft_datetimepicker .xdsoft_label > .xdsoft_select > div > .xdsoft_option {
  padding: 2px 10px 2px 5px;
  text-decoration: none !important; }

.xdsoft_datetimepicker .xdsoft_label > .xdsoft_select > div > .xdsoft_option.xdsoft_current {
  background: #33aaff;
  -webkit-box-shadow: #178fe5 0 1px 3px 0 inset;
          box-shadow: #178fe5 0 1px 3px 0 inset;
  color: #fff;
  font-weight: 700; }

.xdsoft_datetimepicker .xdsoft_month {
  width: 100px;
  text-align: right; }

.xdsoft_datetimepicker .xdsoft_calendar {
  clear: both; }

.xdsoft_datetimepicker .xdsoft_year {
  width: 48px;
  margin-left: 5px; }

.xdsoft_datetimepicker .xdsoft_calendar table {
  border-collapse: collapse;
  width: 100%; }

.xdsoft_datetimepicker .xdsoft_calendar td > div {
  padding-right: 5px; }

.xdsoft_datetimepicker .xdsoft_calendar th {
  height: 25px; }

.xdsoft_datetimepicker .xdsoft_calendar td, .xdsoft_datetimepicker .xdsoft_calendar th {
  width: 14.2857142%;
  background: #f5f5f5;
  border: 1px solid #ddd;
  color: #666;
  font-size: 12px;
  text-align: right;
  vertical-align: middle;
  padding: 0;
  border-collapse: collapse;
  cursor: pointer;
  height: 25px; }

.xdsoft_datetimepicker.xdsoft_showweeks .xdsoft_calendar td, .xdsoft_datetimepicker.xdsoft_showweeks .xdsoft_calendar th {
  width: 12.5%; }

.xdsoft_datetimepicker .xdsoft_calendar th {
  background: #f1f1f1; }

.xdsoft_datetimepicker .xdsoft_calendar td.xdsoft_today {
  color: #33aaff; }

.xdsoft_datetimepicker .xdsoft_calendar td.xdsoft_default,
.xdsoft_datetimepicker .xdsoft_calendar td.xdsoft_current,
.xdsoft_datetimepicker .xdsoft_timepicker .xdsoft_time_box > div > div.xdsoft_current {
  background: #33aaff;
  -webkit-box-shadow: #178fe5 0 1px 3px 0 inset;
          box-shadow: #178fe5 0 1px 3px 0 inset;
  color: #fff;
  font-weight: 700; }

.xdsoft_datetimepicker .xdsoft_calendar td.xdsoft_other_month,
.xdsoft_datetimepicker .xdsoft_calendar td.xdsoft_disabled,
.xdsoft_datetimepicker .xdsoft_time_box > div > div.xdsoft_disabled {
  opacity: 0.5;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
  cursor: default; }

.xdsoft_datetimepicker .xdsoft_calendar td.xdsoft_other_month.xdsoft_disabled {
  opacity: 0.2;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=20)"; }

.xdsoft_datetimepicker .xdsoft_calendar td:hover,
.xdsoft_datetimepicker .xdsoft_timepicker .xdsoft_time_box > div > div:hover {
  color: #fff !important;
  background: #ff8000 !important;
  -webkit-box-shadow: none !important;
          box-shadow: none !important; }

.xdsoft_datetimepicker .xdsoft_calendar td.xdsoft_current.xdsoft_disabled:hover,
.xdsoft_datetimepicker .xdsoft_timepicker .xdsoft_time_box > div > div.xdsoft_current.xdsoft_disabled:hover {
  background: #33aaff !important;
  -webkit-box-shadow: #178fe5 0 1px 3px 0 inset !important;
          box-shadow: #178fe5 0 1px 3px 0 inset !important;
  color: #fff !important; }

.xdsoft_datetimepicker .xdsoft_calendar td.xdsoft_disabled:hover,
.xdsoft_datetimepicker .xdsoft_timepicker .xdsoft_time_box > div > div.xdsoft_disabled:hover {
  color: inherit	!important;
  background: inherit !important;
  -webkit-box-shadow: inherit !important;
          box-shadow: inherit !important; }

.xdsoft_datetimepicker .xdsoft_calendar th {
  font-weight: 700;
  text-align: center;
  color: #999;
  cursor: default; }

.xdsoft_datetimepicker .xdsoft_copyright {
  color: #ccc !important;
  font-size: 10px;
  clear: both;
  float: none;
  margin-left: 8px; }

.xdsoft_datetimepicker .xdsoft_copyright a {
  color: #eee !important; }

.xdsoft_datetimepicker .xdsoft_copyright a:hover {
  color: #aaa !important; }

.xdsoft_time_box {
  position: relative;
  border: 1px solid #ccc; }

.xdsoft_scrollbar > .xdsoft_scroller {
  background: #ccc !important;
  height: 20px;
  border-radius: 3px; }

.xdsoft_scrollbar {
  position: absolute;
  width: 7px;
  right: 0;
  top: 0;
  bottom: 0;
  cursor: pointer; }

.xdsoft_scroller_box {
  position: relative; }

.xdsoft_datetimepicker.xdsoft_dark {
  -webkit-box-shadow: 0 5px 15px -5px rgba(255, 255, 255, 0.506);
          box-shadow: 0 5px 15px -5px rgba(255, 255, 255, 0.506);
  background: #000;
  border-bottom: 1px solid #444;
  border-left: 1px solid #333;
  border-right: 1px solid #333;
  border-top: 1px solid #333;
  color: #ccc; }

.xdsoft_datetimepicker.xdsoft_dark .xdsoft_timepicker .xdsoft_time_box {
  border-bottom: 1px solid #222; }

.xdsoft_datetimepicker.xdsoft_dark .xdsoft_timepicker .xdsoft_time_box > div > div {
  background: #0a0a0a;
  border-top: 1px solid #222;
  color: #999; }

.xdsoft_datetimepicker.xdsoft_dark .xdsoft_label {
  background-color: #000; }

.xdsoft_datetimepicker.xdsoft_dark .xdsoft_label > .xdsoft_select {
  border: 1px solid #333;
  background: #000; }

.xdsoft_datetimepicker.xdsoft_dark .xdsoft_label > .xdsoft_select > div > .xdsoft_option:hover {
  color: #000;
  background: #007fff; }

.xdsoft_datetimepicker.xdsoft_dark .xdsoft_label > .xdsoft_select > div > .xdsoft_option.xdsoft_current {
  background: #cc5500;
  -webkit-box-shadow: #b03e00 0 1px 3px 0 inset;
          box-shadow: #b03e00 0 1px 3px 0 inset;
  color: #000; }

.xdsoft_datetimepicker.xdsoft_dark .xdsoft_label i,
.xdsoft_datetimepicker.xdsoft_dark .xdsoft_prev,
.xdsoft_datetimepicker.xdsoft_dark .xdsoft_next,
.xdsoft_datetimepicker.xdsoft_dark .xdsoft_today_button {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGQAAAAeCAYAAADaW7vzAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6QUExQUUzOTA0M0UyMTFFNDlBM0FFQTJENTExRDVBODYiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6QUExQUUzOTE0M0UyMTFFNDlBM0FFQTJENTExRDVBODYiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDpBQTFBRTM4RTQzRTIxMUU0OUEzQUVBMkQ1MTFENUE4NiIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDpBQTFBRTM4RjQzRTIxMUU0OUEzQUVBMkQ1MTFENUE4NiIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/Pp0VxGEAAAIASURBVHja7JrNSgMxEMebtgh+3MSLr1T1Xn2CHoSKB08+QmR8Bx9A8e7RixdB9CKCoNdexIugxFlJa7rNZneTbLIpM/CnNLsdMvNjM8l0mRCiQ9Ye61IKCAgZAUnH+mU3MMZaHYChBnJUDzWOFZdVfc5+ZFLbrWDeXPwbxIqrLLfaeS0hEBVGIRQCEiZoHQwtlGSByCCdYBl8g8egTTAWoKQMRBRBcZxYlhzhKegqMOageErsCHVkk3hXIFooDgHB1KkHIHVgzKB4ADJQ/A1jAFmAYhkQqA5TOBtocrKrgXwQA8gcFIuAIO8sQSA7hidvPwaQGZSaAYHOUWJABhWWw2EMIH9QagQERU4SArJXo0ZZL18uvaxejXt/Em8xjVBXmvFr1KVm/AJ10tRe2XnraNqaJvKE3KHuUbfK1E+VHB0q40/y3sdQSxY4FHWeKJCunP8UyDdqJZenT3ntVV5jIYCAh20vT7ioP8tpf6E2lfEMwERe+whV1MHjwZB7PBiCxcGQWwKZKD62lfGNnP/1poFAA60T7rF1UgcKd2id3KDeUS+oLWV8DfWAepOfq00CgQabi9zjcgJVYVD7PVzQUAUGAQkbNJTBICDhgwYTjDYD6XeW08ZKh+A4pYkzenOxXUbvZcWz7E8ykRMnIHGX1XPl+1m2vPYpL+2qdb8CDAARlKFEz/ZVkAAAAABJRU5ErkJggg==); }

.xdsoft_datetimepicker.xdsoft_dark .xdsoft_calendar td,
.xdsoft_datetimepicker.xdsoft_dark .xdsoft_calendar th {
  background: #0a0a0a;
  border: 1px solid #222;
  color: #999; }

.xdsoft_datetimepicker.xdsoft_dark .xdsoft_calendar th {
  background: #0e0e0e; }

.xdsoft_datetimepicker.xdsoft_dark .xdsoft_calendar td.xdsoft_today {
  color: #cc5500; }

.xdsoft_datetimepicker.xdsoft_dark .xdsoft_calendar td.xdsoft_default,
.xdsoft_datetimepicker.xdsoft_dark .xdsoft_calendar td.xdsoft_current,
.xdsoft_datetimepicker.xdsoft_dark .xdsoft_timepicker .xdsoft_time_box > div > div.xdsoft_current {
  background: #cc5500;
  -webkit-box-shadow: #b03e00 0 1px 3px 0 inset;
          box-shadow: #b03e00 0 1px 3px 0 inset;
  color: #000; }

.xdsoft_datetimepicker.xdsoft_dark .xdsoft_calendar td:hover,
.xdsoft_datetimepicker.xdsoft_dark .xdsoft_timepicker .xdsoft_time_box > div > div:hover {
  color: #000 !important;
  background: #007fff !important; }

.xdsoft_datetimepicker.xdsoft_dark .xdsoft_calendar th {
  color: #666; }

.xdsoft_datetimepicker.xdsoft_dark .xdsoft_copyright {
  color: #333 !important; }

.xdsoft_datetimepicker.xdsoft_dark .xdsoft_copyright a {
  color: #111 !important; }

.xdsoft_datetimepicker.xdsoft_dark .xdsoft_copyright a:hover {
  color: #555 !important; }

.xdsoft_dark .xdsoft_time_box {
  border: 1px solid #333; }

.xdsoft_dark .xdsoft_scrollbar > .xdsoft_scroller {
  background: #333 !important; }

.xdsoft_datetimepicker .xdsoft_save_selected {
  display: block;
  border: 1px solid #dddddd !important;
  margin-top: 5px;
  width: 100%;
  color: #454551;
  font-size: 13px; }

.xdsoft_datetimepicker .blue-gradient-button {
  font-family: "museo-sans", "Book Antiqua", sans-serif;
  font-size: 12px;
  font-weight: 300;
  color: #82878c;
  height: 28px;
  position: relative;
  padding: 4px 17px 4px 33px;
  border: 1px solid #d7d8da;
  /* FF3.6+ */
  /* Chrome,Safari4+ */
  /* Chrome10+,Safari5.1+ */
  /* Opera 11.10+ */
  /* IE10+ */
  background: -webkit-gradient(linear, left top, left bottom, from(#fff), color-stop(73%, #f4f8fa));
  background: linear-gradient(to bottom, #fff 0%, #f4f8fa 73%);
  /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fff', endColorstr='#f4f8fa',GradientType=0 );
  /* IE6-9 */ }

.xdsoft_datetimepicker .blue-gradient-button:hover, .xdsoft_datetimepicker .blue-gradient-button:focus, .xdsoft_datetimepicker .blue-gradient-button:hover span, .xdsoft_datetimepicker .blue-gradient-button:focus span {
  color: #454551;
  /* FF3.6+ */
  /* Chrome,Safari4+ */
  /* Chrome10+,Safari5.1+ */
  /* Opera 11.10+ */
  /* IE10+ */
  background: -webkit-gradient(linear, left top, left bottom, from(#f4f8fa), color-stop(73%, #FFF));
  background: linear-gradient(to bottom, #f4f8fa 0%, #FFF 73%);
  /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f4f8fa', endColorstr='#FFF',GradientType=0 );
  /* IE6-9 */ }

.api-style #content {
  margin-top: 90px; }

.api-style .api-brand {
  padding: 5px 15px; }

.api-style .nav li {
  color: #76838f; }

.api-style .navbar {
  border-top: 5px solid #354052;
  -webkit-box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
  background: #fff;
  color: #76838f; }
  .api-style .navbar .nav li a, .api-style .navbar .brand:hover {
    color: #76838f; }

.api-style .page-header {
  padding: 0px 230px 0px 0px;
  position: inherit; }

.api-style .icon-exit {
  padding: 0px; }
  .api-style .icon-exit:hover img {
    fill: #000; }

/* Project specific Sass goes here. */
#branding h1, #branding h1 a:link, #branding h1 a:visited {
  color: #fff !important; }

#site-name img {
  margin-right: 10px; }

.btn {
  font-weight: 300; }

.btn-link {
  cursor: pointer;
  padding: 0; }

.btn-primary.disabled, .btn-primary:disabled {
  background-color: #ccc;
  border-color: #999; }

.btn-remove {
  color: #d9534f; }
  .btn-remove:hover {
    color: #c9302c; }

.white {
  color: #fff; }

.ss-pink {
  color: #e90e8b !important; }

.fa-check-square-o {
  color: green; }

.fa-times {
  color: #ff0000; }

button[type="submit"] .fa-times {
  color: #fff; }

.pistola {
  background: url("../images/barcode_grey.png") no-repeat;
  background-size: 8px 14px;
  padding-left: 20px; }

.overflow {
  overflow: hidden; }

html {
  height: 100%;
  -webkit-box-sizing: border-box;
          box-sizing: border-box; }

body {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  line-height: 1.5;
  color: #76838f;
  font-weight: 300;
  background: #f1f4f5;
  position: relative;
  min-height: 100%; }

#icon-cilia {
  fill: #fff; }

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
  font-weight: 400;
  line-height: 1.2;
  color: #37474f;
  text-shadow: rgba(0, 0, 0, 0.15) 0 0 1px; }

b {
  font-weight: 400; }

.black500 {
  color: #000;
  font-weight: 500; }

.bg-blue {
  background-color: #62a8ea;
  color: #fff; }

strong {
  font-weight: 500; }

.thin {
  font-weight: 300; }

.width-50 {
  width: 50px; }

.width-250 {
  width: 250px; }

.width-350 {
  width: 350px; }

.width-450 {
  width: 450px; }

.size-20 {
  font-size: 20px !important; }

/*
 * Badge
 */
.badge {
  font-weight: 500;
  padding: .25em .6em; }
  .badge.badge-normal-size {
    font-size: 100%; }
  .badge.badge-default-outline {
    background: #fff;
    color: #636c72;
    border: 1px solid #636c72; }
  .badge.badge-primary-outline {
    background-color: #fff;
    color: #0275d8;
    border: 1px solid #0275d8; }
  .badge.badge-success-outline {
    background-color: #fff;
    color: #5cb85c;
    border: 1px solid #5cb85c; }
  .badge.badge-info-outline {
    background-color: #fff;
    color: #5bc0de;
    border: 1px solid #5bc0de; }
  .badge.badge-warning-outline {
    background-color: #fff;
    color: #f0ad4e;
    border: 1px solid #f0ad4e; }
  .badge.badge-danger-outline {
    background-color: #fff;
    color: #d9534f;
    border: 1px solid #d9534f; }

/*
 * Navigation
 */
#mainNav {
  height: 60px;
  border: none;
  -webkit-box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
  background: #fff;
  padding: 0px; }
  #mainNav .navbar-brand {
    padding: 0px; }
    #mainNav .navbar-brand .navbar-brand-logo {
      height: 40px;
      margin-left: 10px;
      margin-top: 10px; }
  #mainNav .navbar-nav .nav-item {
    height: 60px; }
  #mainNav .navbar-nav .nav-link {
    line-height: 20px;
    padding: 20px; }
    #mainNav .navbar-nav .nav-link i {
      font-size: 18px; }
    #mainNav .navbar-nav .nav-link.item-upper {
      position: relative; }
    #mainNav .navbar-nav .nav-link .badge {
      position: absolute;
      top: 12px;
      right: 12px; }
  #mainNav .navbar-nav .nav-item:hover {
    color: #526069;
    background-color: rgba(243, 247, 249, 0.6); }
  #mainNav .navbar-nav .nav-item + .nav-item {
    margin-left: 0px; }
  #mainNav .dropdown-menu {
    right: 2px !important;
    left: inherit;
    padding: 3px;
    min-width: 13rem; }
    #mainNav .dropdown-menu .benv {
      font-size: 12px;
      color: #76838f;
      padding: 8px 15px;
      margin: 0; }
      #mainNav .dropdown-menu .benv b {
        color: #e90e8b; }
  #mainNav .dropdown-menu > a {
    font-size: 14px;
    color: #76838f;
    font-weight: 100;
    padding: 8px 15px;
    border-radius: 3px;
    -webkit-transition: background-color .25s;
    transition: background-color .25s; }
    #mainNav .dropdown-menu > a:focus, #mainNav .dropdown-menu > a:hover {
      color: #76838f;
      text-decoration: none;
      background-color: #f3f7f9; }
    #mainNav .dropdown-menu > a i {
      margin-right: 5px; }

#menuBar {
  height: 100%;
  width: 60px;
  position: fixed;
  z-index: 1000;
  top: 60px;
  height: calc(100% - 60px);
  background: #354052;
  /*background: #263238;
  color: rgba(163, 175, 183, .9);*/
  color: #6f7e95;
  font-family: Roboto, sans-serif;
  -webkit-box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
  -webkit-transition: width .25s, opacity .25s, -webkit-transform .25s;
  transition: width .25s, opacity .25s, -webkit-transform .25s;
  transition: width .25s, opacity .25s, transform .25s;
  transition: width .25s, opacity .25s, transform .25s, -webkit-transform .25s; }
  #menuBar li:hover {
    background-color: #2b3647; }
    #menuBar li:hover .categories-panel {
      left: 60px;
      /*opacity: 1;*/ }
  #menuBar .nav-item .parent {
    color: rgba(163, 175, 183, 0.9);
    display: block;
    line-height: 20px;
    padding: 20px; }
    #menuBar .nav-item .parent:hover {
      color: white; }
    #menuBar .nav-item .parent i {
      font-size: 18px; }
  #menuBar .nav-item .categories-panel {
    padding: 20px;
    background-color: #2b3647;
    color: rgba(163, 175, 183, 0.9);
    -webkit-box-shadow: 2px 0 0 0 rgba(0, 0, 0, 0.49);
    box-shadow: 2px 0 0 0 rgba(0, 0, 0, 0.49);
    height: 100%;
    width: 220px;
    position: fixed;
    top: 60px;
    /*left:60px;
      opacity: 0;*/
    left: -400px;
    z-index: -1;
    -webkit-transition: all 400ms ease-in-out;
    transition: all 400ms ease-in-out;
    /*transition: all 400ms cubic-bezier(1.000, 0.000, 0.000, 1.000);
      -webkit-transition: all 400ms cubic-bezier(1.000, 0.000, 0.000, 1.000);
      -moz-transition: all 400ms cubic-bezier(1.000, 0.000, 0.000, 1.000);*/ }
    #menuBar .nav-item .categories-panel h2 {
      font-size: 18px;
      color: #fff;
      font-weight: 500;
      margin-bottom: 20px; }
    #menuBar .nav-item .categories-panel .scroll-pane .nav-item {
      padding: 5px 0px;
      text-transform: uppercase;
      font-weight: 500;
      font-size: 12px; }
      #menuBar .nav-item .categories-panel .scroll-pane .nav-item.white {
        border-bottom: 1px solid; }
    #menuBar .nav-item .categories-panel a {
      color: rgba(163, 175, 183, 0.9);
      font-size: 14px;
      font-weight: 300;
      text-decoration: none;
      text-transform: none !important;
      border-bottom: none; }
      #menuBar .nav-item .categories-panel a i {
        margin-right: 5px; }
      #menuBar .nav-item .categories-panel a:hover {
        color: white; }

#main-content {
  min-height: 700px;
  margin: 60px 0px 0px 60px;
  padding-top: 20px; }

.page {
  margin: 60px 0px 0px 60px;
  background: #f1f4f5;
  min-height: -webkit-calc(100% - 44px);
  min-height: 600px;
  min-height: calc(100% - 44px);
  position: relative; }

.page-content {
  padding: 30px 30px; }

footer {
  position: absolute;
  clear: both;
  bottom: 0px;
  font-size: .8em;
  width: 100%;
  /*margin-left: 60px;
  background-color: #f7f7f9;*/ }
  footer p {
    margin-bottom: 0px; }

.site-footer {
  padding: 15px 30px 15px 70px;
  background-color: rgba(0, 0, 0, 0.02);
  border-top: 1px solid #e4eaec;
  height: 44px; }

/*
 * Messagges
 */
#message {
  padding: 0px; }

/*
 * Page header
 ****************************/
.page-header + .page-content {
  padding-top: 0; }

.page-header {
  padding: 30px 30px;
  margin-top: 0;
  margin-bottom: 0;
  background: none;
  position: relative;
  border-bottom: none; }
  .page-header .page-title {
    font-size: 26px;
    margin-bottom: 0px;
    margin-top: 0; }
  .page-header .breadcrumb {
    padding: 0;
    margin: 0; }

.page-header-bordered {
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: #fff;
  margin-bottom: 30px;
  border-bottom: 1px solid transparent; }

.page-header-actions {
  position: absolute;
  right: 30px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  margin: auto; }
  .page-header-actions > * {
    margin-bottom: 0; }

.total {
  text-align: center;
  border-radius: 3px;
  background-color: #62a8ea;
  color: #fff;
  padding: 8px 10px 0px; }
  .total > .total-number {
    font-size: 25px;
    line-height: 35px; }
  .total .total-label {
    text-transform: uppercase;
    font-size: 10px;
    font-weight: 300;
    line-height: 1em; }

.counter {
  text-align: center;
  margin-left: 10px; }
  .counter > .counter-number {
    color: #37474f;
    font-size: 20px; }
  .counter .counter-label {
    display: block; }

.font-weight-medium {
  font-weight: 400 !important; }

.row.no-space {
  margin-right: 0;
  margin-left: 0; }

.row.no-space > [class*="col-"] {
  padding-right: 0;
  padding-left: 0; }

/*
 * Tag
 ******************/
.tag {
  font-weight: 400; }

/* Breadcrumbs */
.breadcrumb {
  background-color: transparent; }

.breadcrumb > li {
  display: inline-block; }
  .breadcrumb > li a {
    color: #62a8ea; }
  .breadcrumb > li a:hover {
    color: #89bceb; }
  .breadcrumb > li a:active {
    color: #4e97d9; }

.breadcrumb li + li::before {
  padding: 0 5px; }

.breadcrumb > li + li::before {
  padding: 0 5px;
  color: #62a8ea;
  content: "/\00a0"; }

/*
 * Widget
 ***********************/
.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: normal;
  white-space: nowrap; }

.widget {
  border-radius: 3px;
  background-color: #fff;
  margin-bottom: 30px;
  padding-bottom: 25px;
  position: relative; }
  .widget .widget-header {
    padding: 25px 30px 11px;
    border-radius: 3px 3px 0 0;
    /*color: #37474f !important;*/
    color: #e90e8b !important;
    font-size: 14px;
    text-transform: uppercase;
    margin-bottom: 0px;
    font-weight: 400; }
  .widget .widget-content {
    padding: 0 20px; }
    .widget .widget-content::after {
      content: "";
      display: block;
      clear: both; }
  .widget .widget-title {
    margin-top: 0;
    color: #37474f;
    text-transform: capitalize !important;
    font-size: 24px; }
  .widget .widget-body {
    position: relative;
    padding: 30px 25px; }
  .widget .widget-header.no-padding + .widget-body {
    padding-top: 10px !important; }
  .widget .table {
    color: #76838f !important; }

.widget-shadow {
  -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05); }

/*
 * Panel
 ***********************/
.widget {
  border-radius: 3px;
  background-color: #fff;
  margin-bottom: 30px;
  position: relative; }

.widget-shadow {
  -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05); }

.panel {
  position: relative;
  margin-bottom: 30px;
  border-width: 0;
  background-color: #fff;
  border: 1px solid transparent;
  border-radius: 4px;
  -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2); }
  .panel .panel-heading {
    position: relative;
    padding: 0;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px; }
  .panel .panel-center {
    padding: 30px;
    overflow: hidden;
    margin: 0 auto;
    width: 300px; }
    .panel .panel-center .mese-stato {
      font-size: 20px;
      margin: 0px 10px; }
  .panel .panel-actions {
    position: absolute;
    width: auto;
    text-align: right;
    top: 50%;
    right: 30px;
    z-index: 1;
    margin: auto;
    -webkit-transform: translate(0, -50%);
    transform: translate(0, -50%); }
    .panel .panel-actions .panel-action {
      display: inline-block;
      padding: 8px 10px;
      color: #a3afb7;
      text-decoration: none;
      cursor: pointer;
      background-color: transparent;
      font-size: 18px; }
  .panel .panel-body {
    position: relative;
    padding: 30px 30px; }
  .panel .panel-title {
    display: block;
    padding: 20px 30px;
    font-size: 18px;
    color: #37474f;
    margin-top: 0;
    margin-bottom: 0; }
  .panel .panel-desc {
    display: block;
    padding: 5px 0 0;
    margin: 0;
    font-size: 14px;
    color: #76838f; }
  .panel .panel-footer {
    padding: 0 30px 15px;
    background-color: transparent;
    border-top: 1px solid #e4eaec;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px; }
  .panel > .alert-dismissible {
    padding-right: 50px; }
  .panel > .alert {
    padding-right: 30px;
    padding-left: 30px;
    margin: 0; }
  .panel .result_search {
    display: inline;
    float: left;
    font-size: 1.5em;
    margin-top: 5px; }
    .panel .result_search em {
      font-weight: 400;
      color: #000; }

.panel-heading + .panel-body {
  padding-top: 0px; }

.panel-heading + .panel-body.padding {
  padding-top: 30px; }

.panel-heading + .alert {
  border-radius: 0; }

.panel-bordered > .panel-heading {
  border-bottom: 1px solid #e4eaec; }

.panel-bordered > .panel-heading > .panel-title {
  padding-bottom: 20px; }

.panel-bordered > .panel-footer {
  padding-top: 15px;
  border-top: 1px solid #e4eaec; }

.panel-bordered .panel-body {
  padding-top: 30px; }

.panel-danger, .panel-dark, .panel-info, .panel-primary, .panel-success, .panel-warning {
  border: none; }

.panel-primary > .panel-heading {
  color: #fff;
  background-color: #62a8ea;
  border-color: #62a8ea; }

.panel-success > .panel-heading {
  color: #fff;
  background-color: #46be8a;
  border-color: #3dae6a; }

.panel-danger > .panel-heading {
  color: #fff;
  background-color: #d9534f;
  border-color: #d9534f; }

.panel-info > .panel-heading {
  color: #fff;
  background-color: #5bc0de;
  border-color: #5bc0de; }

.panel-warning > .panel-heading {
  color: #fff;
  background-color: #f18246;
  border-color: #f18246; }

.panel-danger .panel-title, .panel-dark .panel-title, .panel-info .panel-title, .panel-primary .panel-title, .panel-success .panel-title, .panel-warning .panel-title {
  color: #fff; }

input::-webkit-input-placeholder, input::-moz-placeholder, input:-ms-input-placeholder, input:-moz-placeholder {
  color: #CCCCCC !important; }

#toast-container > .toast {
  background-image: none !important; }

#toast-container > .toast:before {
  position: fixed;
  font-family: FontAwesome;
  font-size: 24px;
  line-height: 18px;
  float: left;
  color: #FFF;
  padding-right: 0.5em;
  margin: auto 0.5em auto -1.5em; }

#toast-container > .toast-warning:before {
  content: "\f06a"; }

#toast-container > .toast-error:before {
  content: "\f256"; }

#toast-container > .toast-info:before {
  content: "\f05a"; }

#toast-container > .toast-success:before {
  content: "\f00c"; }

/*
 * Page: Fdp mese
 */
#fdp-panel .panel-title {
  font-size: 22px; }

#fdp-panel .panel-body {
  padding: 0px 30px 30px; }

.month-nav {
  overflow: hidden; }
  .month-nav > * {
    float: left;
    margin-left: .75em; }
  .month-nav button[name='next'] {
    margin-left: 0px; }
  .month-nav .btn:focus {
    box-shadow: none !important;
    -webkit-box-shadow: none !important; }

.table-info {
  font-weight: 600; }

.cell-grey {
  background: #f8f8f8 !important; }

#report_fdp_table tbody tr:hover {
  background-color: rgba(0, 0, 0, 0.035); }

#report_fdp_table .novpa, #report_fdp_table .vpa {
  border: 1px solid #eceeef;
  text-align: center;
  vertical-align: middle; }

#report_fdp_table .tot-vpa {
  color: #292b2c;
  font-weight: 400; }

#report_fdp_table .cod-vpa {
  border: none; }

#riepilogo_table td, #report_fdp_table td {
  color: #292b2c; }
  #riepilogo_table td .text-center, #report_fdp_table td .text-center {
    font-weight: 400; }

#riepilogo_table .data {
  min-width: 95px;
  width: 95px; }

#riepilogo_table .tempi {
  min-width: 50px;
  width: 50px; }

.tot-servizi {
  float: left;
  width: 150px;
  border-right: dotted 3px #ccc;
  margin-right: 20px; }

.lista-codici-title, .tot-servizi-title {
  font-weight: 300;
  font-size: 16px; }

.vpa-tot {
  width: 60px;
  height: 65px;
  border-radius: 4px;
  color: #fff;
  display: inline-block;
  margin-right: 5px;
  margin-bottom: 5px;
  padding: 5px;
  text-align: center;
  font-size: 20px;
  font-weight: 300;
  position: relative; }
  .vpa-tot.vbig {
    width: 120px;
    font-size: 16px;
    padding-top: 10px; }
  .vpa-tot .time {
    font-weight: 500;
    display: block;
    font-size: 14px;
    margin-top: 3px;
    border-radius: 0px 0px 2px 2px;
    color: #000;
    background: #fff; }

#cambia-autista {
  float: left;
  margin-right: 15px;
  padding-right: 15px;
  border-right: 1px solid #ccc; }

/*
 * Pagina creazione barcode
 */
#crea-barcode-form label {
  margin-top: 40px;
  text-align: right;
  text-align: right;
  font-weight: 500;
  text-transform: uppercase;
  display: block; }

#crea-barcode-form .help {
  font-style: italic;
  margin-bottom: .5rem; }

/*
 * Page: Fogli servizi mese
 */
#fservizio-panel .panel-title {
  font-size: 22px; }

#fservizio-panel .panel-body {
  padding: 0px 30px 30px; }

form .form-control-feedback {
  margin-left: 15px;
  color: red;
  font-weight: 500; }

/*
 * Page: Operativo home
 */
#grafico_fogli_presenza {
  margin: 30px auto;
  width: 350px;
  height: auto; }

/*
 * Page: Gestionale home
 */
#grafico_clienti {
  margin: 30px auto;
  width: 100%;
  max-width: 500px;
  height: auto; }

/*
 * Tooltips
 */
.tooltip-inner {
  font-size: 12px; }

/*
 * Pagination
 **********************/
#pagination {
  margin: 15px;
  /*float: right;*/
  width: auto;
  text-align: right; }
  #pagination .current {
    float: left;
    font-size: 14px;
    margin-right: 15px;
    margin-top: 10px; }
  #pagination ul {
    margin: 0px; }

#totali .numero {
  z-index: 2;
  color: #fff;
  font-weight: 600;
  background-color: #a25808;
  border-color: #f18246;
  border-radius: .25rem;
  padding: .5rem .75rem;
  margin-left: 10px; }

/*
 * Table
 *********************/
#toolbar {
  margin-top: 10px; }

.fixed-table-container {
  border: none !important; }

.fixed-table-toolbar .columns label {
  font-size: 13px;
  padding: 3px 10px !important;
  font-weight: 300; }

.fixed-table-body {
  margin: 0px 0px 20px; }
  .fixed-table-body tr > td {
    font-size: 12px; }

.bootstrap-table .table {
  border: none !important; }
  .bootstrap-table .table > thead > tr > th {
    font-size: 11px;
    text-transform: uppercase;
    border-bottom: 2px solid #eceeef !important; }
  .bootstrap-table .table > tr > td, .bootstrap-table .table table .table:not(.table-condensed) > tbody > tr > td {
    font-size: 12px; }

.fixed-table-pagination .pagination-info {
  margin-right: 20px !important; }

.fixed-table-pagination .dropup .dropdown-menu {
  margin-bottom: 1px !important;
  min-width: 6rem !important; }

.fixed-table-toolbar .dropdown-menu > li a, .fixed-table-pagination .dropdown-menu > li a {
  font-size: 14px;
  color: #76838f;
  font-weight: 100;
  display: block;
  padding: 0px 15px;
  border-radius: 3px;
  -webkit-transition: background-color .25s;
  transition: background-color .25s; }
  .fixed-table-toolbar .dropdown-menu > li a:focus, .fixed-table-toolbar .dropdown-menu > li a:hover, .fixed-table-pagination .dropdown-menu > li a:focus, .fixed-table-pagination .dropdown-menu > li a:hover {
    color: #76838f;
    text-decoration: none;
    background-color: #f3f7f9; }
  .fixed-table-toolbar .dropdown-menu > li a i, .fixed-table-pagination .dropdown-menu > li a i {
    margin-right: 5px; }

.fixed-table-pagination .page-number.active {
  background: #0275d8;
  border-radius: 3px; }
  .fixed-table-pagination .page-number.active a {
    color: #fff;
    text-decoration: none;
    font-weight: 400; }

.fixed-table-pagination div.pagination {
  margin-top: 20px !important; }

.fixed-table-pagination .pagination-detail {
  margin-top: 10px; }

.table-success, .table-success > td, .table-success > th {
  background-color: #dff0d8;
  color: green;
  font-weight: 600; }

.table-danger, .table-danger > td, .table-danger > th {
  background-color: #f2dede;
  color: darkred;
  font-weight: 600; }

.table th {
  font-weight: 500;
  color: #292b2c; }

.thead-default th {
  background-color: transparent !important; }

.table-sm td, .table-sm th {
  font-size: 12px; }

#fogli_presenze_table .progress {
  margin-top: 4px; }

table.month {
  width: 100%; }
  table.month th {
    font-weight: 400;
    text-transform: uppercase;
    text-align: center; }
    table.month th.month {
      text-transform: none;
      font-size: 20px;
      font-weight: 400;
      line-height: 1.2;
      color: #37474f;
      text-shadow: rgba(0, 0, 0, 0.15) 0 0 1px;
      border-top: 0;
      padding-bottom: 20px; }
  table.month tr td {
    min-width: 150px;
    width: calc(100% / 7);
    position: relative;
    height: 100px;
    min-height: 100px;
    padding: 30px 5px 5px; }
    table.month tr td .add-vpa {
      position: absolute;
      top: 1px;
      left: 5px;
      z-index: 100; }
      table.month tr td .add-vpa:focus {
        outline: none !important; }
    table.month tr td .cr-day-number {
      font-weight: 500;
      color: #37474f;
      font-size: 20px;
      position: absolute;
      right: 5px;
      top: 1px; }
    table.month tr td.tue, table.month tr td.wed, table.month tr td.thu, table.month tr td.fri, table.month tr td.sat, table.month tr td.sun {
      border-left: 1px solid #eceeef; }
    table.month tr td.riposo, table.month tr td + .riposo {
      background-color: #fcf8e3; }
      table.month tr td.riposo .cr-day-number, table.month tr td + .riposo .cr-day-number {
        color: #d9534f; }
    table.month tr td.zeroore .cr-day-number, table.month tr td + .zeroore .cr-day-number {
      color: #0275d8; }
    table.month tr td .vpa-item {
      background: #f3f7f9;
      position: relative;
      padding: 0px 15px 0px 5px;
      height: auto;
      line-height: 22px;
      color: #37474f;
      border-radius: 3px;
      margin-bottom: 2px;
      font-size: 11px; }
      table.month tr td .vpa-item.OR {
        background: #5cb85c;
        color: #fff !important; }
      table.month tr td .vpa-item.ST, table.month tr td .vpa-item.SF, table.month tr td .vpa-item.FM, table.month tr td .vpa-item.MN {
        background: #5bc0de;
        color: #fff; }
      table.month tr td .vpa-item.FE, table.month tr td .vpa-item.CM, table.month tr td .vpa-item.PE, table.month tr td .vpa-item.PL, table.month tr td .vpa-item.PR, table.month tr td .vpa-item.SR, table.month tr td .vpa-item.VM {
        background: #d9534f;
        color: #fff; }
      table.month tr td .vpa-item.ML, table.month tr td .vpa-item.IN, table.month tr td .vpa-item.P5, table.month tr td .vpa-item.P4 {
        background: #f0ad4e;
        color: #fff; }
      table.month tr td .vpa-item.DS {
        background: #0275d8;
        color: #FFF; }
      table.month tr td .vpa-item .vpa-item-time {
        font-weight: 500;
        background: #fff;
        color: #000;
        padding: 1px 3px;
        border-radius: 2px;
        margin-right: 3px; }
      table.month tr td .vpa-item .btn-remove {
        position: absolute;
        line-height: 1em;
        right: 0px;
        top: 1px;
        padding: 0px 2px 0px 0px; }

/*
 * Form
 **********************/
#foglio_servizio_form .form-group {
  margin-bottom: 2rem; }

#foglio_servizio_form .comune-title {
  color: #62a8ea;
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 20px;
  text-transform: uppercase; }

#foglio_servizio_form .turno-line {
  margin-bottom: 20px;
  overflow: hidden; }

#foglio_servizio_form .turno .nome-turno {
  margin-bottom: 0;
  font-size: 16px; }

#foglio_servizio_form .turno .orario {
  color: #76838f;
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 0; }

#formFogliMarcia .form-control-sm, #formFogliMarcia .input-group-sm > .form-control, #formFogliMarcia .input-group-sm > .input-group-addon, #formFogliMarcia .input-group-sm > .input-group-btn > .btn {
  font-size: .775rem !important; }

#vpaModalForm label, #codSuppModalForm label {
  font-weight: 400; }

#vpaModalForm #p_m_giorno, #codSuppModalForm #p_m_giorno {
  margin-bottom: 0px; }

#vpaModalForm .badge, #codSuppModalForm .badge {
  font-size: 95%; }

#vpaModalForm .help-block, #codSuppModalForm .help-block {
  margin: 5px 30px;
  color: #d9534f; }

/*
 * Calendar
 ************************/
.fc td.fc-widget-header {
  padding-top: 20px;
  padding-bottom: 20px;
  border-width: 0;
  font-size: 14px;
  text-transform: uppercase; }

.fc td.fc-widget-content {
  border-width: 1px 0 0; }

.fc td.fc-day.fc-widget-content + .fc-widget-content {
  border-left-width: 1px; }

.fc .fc-row {
  border-width: 0; }

.fc table > thead > tr > th {
  border-width: 0;
  font-weight: 100; }

.fc .fc-toolbar h2 {
  display: inline-block;
  font-size: 20px;
  vertical-align: sub; }

.fc .fc-axis.fc-time {
  text-transform: uppercase; }

.fc .fc-toolbar {
  position: relative; }

.fc-button.fc-next-button, .fc-button.fc-prev-button {
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  background-image: none;
  background-color: transparent;
  border: 0 solid transparent; }

.fc-button.fc-agendaDay-button, .fc-button.fc-agendaWeek-button, .fc-button.fc-month-button {
  height: auto;
  padding: 8px 14px;
  font-size: 14px;
  -webkit-box-shadow: none;
  box-shadow: none;
  background-image: none;
  text-transform: capitalize;
  background-color: #f6f8f8;
  border-color: #e4eaec;
  outline: 0; }

.fc-button.fc-state-active, .fc-button.fc-state-hover {
  background-color: #fff; }

.fc-unthemed .fc-today {
  background-color: #f4f7f8; }

.fc-toolbar .fc-right {
  position: absolute;
  right: 0;
  top: 0; }

.fc-agendaDay-view .fc-event, .fc-agendaWeek-view .fc-event {
  border-radius: 3px; }

.fc-row.fc-widget-header {
  border-color: transparent; }

.calendar-container {
  overflow: hidden;
  padding: 30px; }

.panel-heading {
  border-width: 0;
  padding-top: 10px; }

.panel-body {
  padding-top: 10px; }

.page-main {
  background-color: #fff; }

.calendar-list .list-group-item {
  cursor: pointer; }

.fc-event {
  border-radius: 10px;
  background-color: #62a8ea;
  border-color: #62a8ea; }

.fc-day-grid-event .fc-content {
  line-height: 1em;
  padding: 2px 10px; }

.fc-highlight {
  background: #f3f7f9; }

.bootstrap-touchspin .input-group-btn-vertical i {
  left: 3px; }

@media (max-width: 991px) {
  .fc-toolbar {
    margin-bottom: 60px; }
  .fc-toolbar .fc-center {
    white-space: nowrap; }
  .fc-toolbar .fc-right {
    top: 50px; }
  .fc-button.fc-prev-button {
    padding-left: 0; }
  .fc-button.fc-next-button {
    padding-right: 0; } }

/* Notify */
.alertify-logs {
  z-index: 99999 !important; }

.alert-error {
  background-color: #f2dede;
  border-color: #ebcccc;
  color: #a94442; }

/* Absolute Center Spinner */
#wait {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: visible;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  color: #000; }

/* Transparent Overlay */
#wait:before {
  content: '';
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.7); }
